import { East } from '@mui/icons-material';
import { CircularProgress, Popover, Popper, Skeleton, useMediaQuery } from '@mui/material';
import { getDeviceTypeNotation, sortDates } from 'helpers';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { socket } from 'utils/socket';
import NoSubAdmin from './chat/NoSubAdmin';
import Header from './chat/Header';
import UserChat, { AdminChat, ContextMenu } from './chat/UserChat';
import OnboardingContext, { useGetUserId, useGetUserType } from 'context/OnboardingContext';
import useOutsideClick from 'Hook/useOutsideClick';
import { CHAT_MESSAGE_COUNT, IS_CHAT_OPEN } from 'constants/actions';
import SlidAnimation from 'components/animations/SlidAnimation';
import { apiUploadMultipleFiles } from 'services/api';
import { AttachedFile, ChatArea } from './chat/ChatArea';
export const initialSize = {
  width: 300,
  height: 390,
};
/**
 * A functional component that renders a chat box with a sliding animation.
 *
 * @param {object} children - The child elements to be rendered inside the chat box.
 * @return {JSX.Element} The JSX element representing the chat box.
 */
const ChatBox = ({ children }) => {
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  const [size, setSize] = useState(initialSize);

  const sender = useGetUserId();
  const sender_type = useGetUserType();

  const [imageInfo, setImageInfo] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [chats, setChats] = useState([]);
  const isMobile = useMediaQuery('(max-width : 699px)');

  const getUnreadCount = (data) => {
    dispatchOnboardingForms({
      type: CHAT_MESSAGE_COUNT,
      payload: data?.result ? data?.result?.unreadCount : data?.unreadCount,
    });
  };

  const handleStatusChange = (data) => {
    // console.log('statusChange', data);
    // if (data.chat_status === 'offline') {
    //   dispatchOnboardingForms({
    //     type: IS_CHAT_OPEN,
    //     payload: false,
    //   });
    //   setChats([]);
    // }
  };
  const handleSocketUpdate = (data) => {
    // console.log('updateSocket', data);
    socket.emit('unreadCount', { sender });
  };

  useEffect(() => {
    const handleUnreadCount = (data) => {
      // console.log('unreadCount__', data);
      getUnreadCount(data);
    };
    if (socket) {
      socket.emit('updateSocket', { sender, sender_type, chat_type: sender_type });
    }

    if (socket) {
      socket.on('unreadCount', handleUnreadCount);
      socket.on('updateSocket', handleSocketUpdate);
    }

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.off('unreadCount', handleUnreadCount);
        socket.off('updateSocket', handleSocketUpdate);
      }
    };
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.emit('statusChange', {
        sender,
        sender_type,
        type: onboardingForms.isChatOpen.open ? 'online' : 'idle',
      });

      socket.on('statusChange', handleStatusChange);
    }

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.off('statusChange', handleStatusChange);
      }
    };
    // eslint-disable-next-line
  }, [onboardingForms.isChatOpen.open]);

  return (
    <>
      <div
        className='chat-overlay'
        style={{
          display: isMobile && onboardingForms.isChatOpen.open ? 'block' : 'none',
        }}
      ></div>
      <div id='chat-container' className='main-chat-wrapper'>
        <SlidAnimation className='chat-main-container' open={onboardingForms.isChatOpen.open}>
          <ChatArea size={size} setSize={setSize}>
            <SiteChat
              size={size}
              setSize={setSize}
              // only img
              imageInfo={imageInfo}
              setImageInfo={setImageInfo}
              // only pdf
              setFileInfo={setFileInfo}
              fileInfo={fileInfo}
              // chat
              chats={chats}
              setChats={setChats}
            />
          </ChatArea>
        </SlidAnimation>
      </div>
    </>
  );
};

export default ChatBox;

/**
 * Handles all the logic for the SiteChat component, including the chat messages,
 * the user's input, sending messages, receiving messages, and displaying the
 * conversation history.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - size: The size of the chat box, either 'small', 'medium', or 'large'.
 *   - setSize: The function to set the size of the chat box.
 *   - setImageInfo: The function to set the image info.
 *   - imageInfo: The image info object containing the image files and media.
 *   - setFileInfo: The function to set the file info.
 *   - fileInfo: The file info object containing the file and media.
 *   - chats: The array of chat objects containing the message, sender, and receiver.
 *   - setChats: The function to set the chats array.
 */
const SiteChat = ({
  size,
  setSize,
  setImageInfo,
  imageInfo,
  setFileInfo,
  fileInfo,
  chats,
  setChats,
}) => {
  const lastChat = useRef(null);
  const lastChatBottom = useRef(null);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  const isMobile = useMediaQuery('(max-width : 699px)');

  const sender_type = useGetUserType();
  const sender = useGetUserId();

  const [message, setMessage] = useState('');
  const [receiver, setReceiver] = useState(null); // sub admin data
  const [group_id, setGroupId] = useState('');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [editChat, setEditChat] = useState(null);
  const [imgUploading, setImgUploading] = useState(false);

  // infinite scroll
  const [scrollInfo, setScrollInfo] = useState({
    page: 1,
    limit: 30,
    currentScroll: 0,
    loading: false,
    isFullScrolled: false,
  });

  const receiver_type = 'subadmin';
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  useOutsideClick('chat-container', closePopup);

  function closePopup() {
    setAnchorEl(null);
    if (onboardingForms.isChatOpen.open) {
      dispatchOnboardingForms({ type: IS_CHAT_OPEN, payload: { open: false, isResize: false } });
    }
  }

  const getSubAdmin = (data) => {
    setReceiver(data?.result);
    initChat(data?.result);
  };

  const initChat = (receiver) => {
    const receiverId = receiver?.subadmin_id;
    if (socket && receiverId && sender) {
      socket.emit('initChat', {
        sender,
        sender_type,
        receiver: receiverId,
        receiver_type,
        device_type: getDeviceTypeNotation(),
      });
    }
  };
  // groupId = group_id

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const shortAndRemoveDuplicate = (prev, singleData) => {
    const findDuplicate = prev.find((ele) => ele?._id === singleData?.result[0]?._id);
    if (findDuplicate) {
      return prev;
    }
    const result = [...singleData?.result, ...prev];
    const sorted = sortDates(result, 'createdAt');
    return sorted;
  };

  const getConversationListHandler = (data, page) => {
    setScrollInfo((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });

    if (data?.result?.length === 0) {
      setScrollInfo((prev) => {
        return {
          ...prev,
          isFullScrolled: true,
        };
      });
    }

    if (data.result.length > 0) {
      setChats((prev) => {
        const findDuplicate = prev.find((ele) => ele?._id === data?.result[0]?._id);
        if (findDuplicate) {
          return prev;
        }
        const result = [...data?.result, ...prev];
        const sorted = sortDates(result, 'createdAt');
        return sorted;
      });

      setScrollInfo((prev) => {
        scrollToBottomLast();
        if (prev.page <= 1) {
        } else {
          scrollToBottom();
        }
        return {
          ...prev,
          page: prev.page + 1,
        };
      });
    }
    // scrollToBottom();
  };

  const getOldChat = (receiverId, _, page) => {
    if (socket && receiverId) {
      setScrollInfo((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      socket.emit('getConversationList', {
        sender: sender,
        receiver: receiverId,
        sender_type,
        limit: scrollInfo.limit,
        page: page,
      });
      socket.emit('readAllMessages', {
        sender: sender,
        receiver: receiverId,
        sender_type,
      });
    }
  };

  const scrollToBottom = () => {
    const div = lastChat.current;
    if (div) {
      div.scrollIntoView();
    }
  };
  const scrollToBottomLast = () => {
    const div = lastChatBottom.current;
    if (div) {
      // div.scrollIntoView({ behavior: 'smooth' });
      div.scrollIntoView();
    }
  };

  const uploadFiles = async (imgData) => {
    if (!imgData) return null;
    setImgUploading(true);

    const files = imgData.files; // Use existing formData
    const formData = new FormData();
    const type = imgData.media.map((i) => i.type);
    for (let i = 0; i < files.length; i++) {
      // Assuming formData is an array
      formData.append('files', files[i]);
    }
    formData.append('type', JSON.stringify(type));

    return await apiUploadMultipleFiles(formData);
  };

  const sendMessage = async (e) => {
    e.stopPropagation();
    textareaRef.current.focus();

    const result = await uploadFiles(imageInfo);
    setImgUploading(false);
    setImageInfo(null);
    const media =
      result &&
      result?.data?.data.map((ele) => {
        return {
          thumbnail: '',
          mediaUrl: ele.url,
          type: ele.type,
        };
      });

    if ((message?.trim() && socket && group_id) || media?.length > 0) {
      socket.emit('sendMessage', {
        sender,
        receiver: group_id,
        sender_type,
        message: message?.trim() || '',
        message_type: media ? 'media' : 'text',
        media: media,
      });

      // setChats((chats) => [...chats, { sender_type, message: message?.trim() }]);
      setMessage('');
      scrollToBottomLast();
    }
  };

  const resUnsendMessage = (data) => {
    console.log(data, 'unsendMessage');
    if (data?.result) {
      setChats((chats) => {
        return chats.filter((ele) => ele?._id !== data?.result?._id);
      });
    }
  };

  const handleUnsendMessage = async () => {
    if (socket && group_id) {
      socket.emit('unsendMessage', {
        sender,
        group_id,
        sender_type,
        messageId: selectedChat?._id,
      });
      setChats((chats) => {
        return chats.filter((ele) => ele?._id !== selectedChat?._id);
      });
    }
  };

  function handleCloseHeader(event) {
    const clickedElement = event.target;
    // console.log(clickedElement.id, 'getting_event');

    event.stopPropagation();
    setOpen(false);
    // setAnchorEl(null);
    // setContextMenu((pre) => {
    //   return { ...pre, visible: false };
    // });
  }

  const getAdminName = `${receiver?.first_name || ''} ${receiver?.last_name || ''}`;

  /**
   * Handle clear image function.
   *
   * @param {Event} e - The event object.
   * @return {void}
   */
  const handleClearImage = (e, media, index) => {
    e.stopPropagation();
    setImageInfo((pre) => {
      // Remove file
      let newFiles = [];
      for (let i = 0; i < pre.files.length; i++) {
        if (i !== index) {
          newFiles.push(pre.files[i]);
        }
      }
      return {
        ...pre,
        files: newFiles,
        media: pre.media.filter((item, i) => i !== index),
      };
    });
  };

  // Function to adjust the height of the textarea
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on content
    }
  };

  const handleScroll = (e) => {
    // setScrollTop(e.target.scrollTop);
    if (e.target.scrollTop === 0 && receiver?.subadmin_id) {
      getOldChat(group_id, receiver?.subadmin_id, scrollInfo.page);
    }
  };

  const handleContextMenu = (event, data) => {
    // if(event){}
    // event.preventDefault(); // Prevent default context menu from showing
    // event.stopPropagation();

    // console.log(event.currentTarget, 'data____');
    // // setAnchorEl(event.currentTarget);

    // const rect = event.target.getBoundingClientRect();
    // setContextMenu({
    //   top: rect.top,
    //   left: rect.left,
    //   visible: true,
    // });
    setSelectedChat(data);
  };

  const handleEditMessageResult = (data) => {
    console.log('handleEditMessage', data);
    if (data.result) {
      setChats((prevChats) => {
        return prevChats.map((chat) =>
          chat._id === data.result._id
            ? { ...chat, ...data?.result } // Update the content of the chat message
            : chat,
        );
      });
    }
  };

  const handleUpdateMessage = (event, message, chatData) => {
    if (message?.trim() === '') {
      return;
    }
    setEditChat(null);
    if (socket && receiver) {
      // sender, group_id, messageId, newContent, sender_type
      socket.emit('editMessage', {
        sender: sender,
        receiver: receiver.subadmin_id,
        group_id,
        sender_type,
        newContent: message?.trim(),
        messageId: chatData?._id,
      });

      setChats((prevChats) => {
        return prevChats.map((chat) =>
          chat._id === chatData._id
            ? { ...chat, message: message?.trim() } // Update the content of the chat message
            : chat,
        );
      });
    }
  };

  const resReadAllMessage = (data) => {
    console.log(data, 'resReadAllMessage');
    dispatchOnboardingForms({
      type: CHAT_MESSAGE_COUNT,
      payload: 0,
    });
  };

  const handleEditMessage = (data) => {
    handleEditMessageResult(data);
  };

  const readAllMessage = (data) => {
    resReadAllMessage(data);
  };

  const unsendMessageRes = (data) => {
    resUnsendMessage(data);
  };

  const handleNewConversations = (data) => {
    getConversationListHandler(data);
  };

  const connectionHandler = () => console.log('connected');

  const removeDuplicatesById = (array) => {
    const seen = new Set();

    return array.filter((item) => {
      const duplicate = seen.has(item._id);
      seen.add(item.id);
      return !duplicate; // Keep only the first occurrence of each id
    });
  };

  const receiveMessageHandler = (data) => {
    if (data?.result) {
      setChats((chats) => {
        const newData = removeDuplicatesById([...chats, data?.result]);
        return newData.map((chat) => {
          if (chat._id === data?.result?._id) {
            return data?.result;
          }
          return chat;
        });
      });
      scrollToBottomLast();

      socket.emit('readMessage', {
        sender,
        messageId: data?.result?._id,
        receiver: receiver?.subadmin_id,
        sender_type,
      });
    }
  };
  const handleInitChat = (data) => {
    if (data?.result?._id) {
      setGroupId(data?.result?._id || '');
      getOldChat(data?.result?._id, receiver?.subadmin_id, 0);
    }
  };
  const handleReadMessage = (data) => {
    console.log('message_read', data);
  };

  const handleSubadminStatusUpdated = (data) => {
    socket.emit('subadminAvailability', {
      sender,
    });
    console.log(data, 'this_data_subadmin_status_updated');
  };
  const handleStatusChange = (data) => {
    console.log(data, 'this_data_status_changed');
    socket.emit('subadminAvailability', {
      sender,
    });
    setChats([])
    // if (data.chat_status === 'offline'){
      
    // }
  }

  const handleKeyDown = (event) => {
    if (isMobile || imgUploading) return;
    // event.nativeEvent.shiftKey
    console.log('event_d', event?.target?.value);
    if (event.key === 'Enter') {
      if (!event.altKey && !event.shiftKey) {
        event.preventDefault(); // Prevent the newline from being added
      }
      if (event.altKey) {
        setMessage((pre) => {
          return pre + '\n';
        });
        return;
      }

      if (event.shiftKey) {
        console.log('Shift+Enter pressed');
        return;
      }
      if (!imageInfo && event.target?.value?.trim() === '') {
        // if you select img and want to send when press enter
        return;
      } else {
        sendMessage(event);
      }
    }
  };
  const handleSendMessage = (data) => {
    console.log('dataReciverMsa', data);
  };

  const handleClickOutside = (event) => {
    console.log('clickedElement___', event);
    setAnchorEl(false);
    // if (popperRef.current && !popperRef.current.contains(event.target)) {
    //   setAnchorEl(false); // Close the Popper
    // }
  };

  useEffect(() => {
    adjustHeight();
  }, [message]);

  useEffect(() => {
    if (socket) {
      // emit the subadminAvailability event
      socket.emit('subadminAvailability', {
        sender,
      });

      //TODO: connection is not working
      socket.on('connection', connectionHandler);
      socket.on('subadminAvailabilityResponse', getSubAdmin);
      socket.on('receiveMessage', receiveMessageHandler);
      socket.on('sendMessage', handleSendMessage);
      socket.on('getConversationList', handleNewConversations);
      socket.on('unsendMessage', unsendMessageRes);
      socket.on('readAllMessages', readAllMessage);
      socket.on('editMessage', handleEditMessage);
      socket.on('readMessage', handleReadMessage);
      socket.on('initChat', handleInitChat);
      socket.on('subadminStatusUpdated', handleSubadminStatusUpdated);
      socket.on('statusChange', handleStatusChange);
    }

    return () => {
      socket.off('connection', connectionHandler);
      socket.off('subadminAvailabilityResponse', getSubAdmin);
      socket.off('receiveMessage', receiveMessageHandler);
      socket.off('sendMessage', handleSendMessage);
      socket.off('getConversationList', handleNewConversations);
      socket.off('unsendMessage', unsendMessageRes);
      socket.off('readAllMessages', readAllMessage);
      socket.off('editMessage', handleEditMessage);
      socket.off('readMessage', handleReadMessage);
      socket.off('initChat', handleInitChat);
      socket.off('subadminStatusUpdated', handleSubadminStatusUpdated);
      socket.off('statusChange', handleStatusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (_open) {
      containerRef.current.addEventListener('mousedown', handleClickOutside);
    } else {
      containerRef.current?.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      containerRef?.current?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [_open]);

  console.log(_open, anchorEl, 'anchorEl___');
  return (
    <div
      style={{ overflowY: 'scroll', flex: 1 }}
      className='chat-box-resized-wrap'
      ref={containerRef}
      onScroll={handleScroll}
    >
      <Header
        open={open}
        setOpen={setOpen}
        getAdminName={getAdminName}
        setImageInfo={setImageInfo}
        setFileInfo={setFileInfo}
        receiver={receiver}
      />

      <div
        className='chat-area'
        onClick={handleCloseHeader}
        style={{
          display: !receiver?.subadmin_id && 'block',
        }}
      >
        {!receiver?.subadmin_id && <NoSubAdmin />}

        {chats.length > 0 && (
          <div className='user-chat-area-wrap '>
            {scrollInfo.loading && <Skeleton animation='wave' className='chat-skeleton' />}
            {chats.map((chat, index) => {
              return (
                <>
                  {scrollInfo.limit === index && !scrollInfo.isFullScrolled && (
                    <div className='chat-scroll-point' ref={lastChat} key={'lastChat'}></div>
                  )}
                  {chat.sender_type !== sender_type ? (
                    <AdminChat chat={chat} setChats={setChats} />
                  ) : (
                    <UserChat
                      handleContextMenu={handleContextMenu}
                      chat={chat}
                      editChat={editChat}
                      selectedChat={selectedChat}
                      handleUpdateMessage={handleUpdateMessage}
                      setAnchorEl={setAnchorEl}
                    />
                  )}
                </>
              );
            })}
            <div key={'lastChatBottom'} id='lastChatBottom' ref={lastChatBottom}></div>
            <Popper id={id} open={_open} anchorEl={anchorEl} placement='right-end'>
              <ContextMenu
                visible={anchorEl}
                setEditChat={setEditChat}
                selectedChat={selectedChat}
                handleUnsendMessage={handleUnsendMessage}
              />
            </Popper>
          </div>
        )}
      </div>

      {/* Chat footer */}

      <div
        className={`w-full chat-msg-send-wrap ${!receiver?.subadmin_id && 'pointer-events-none'}`}
      >
        <div className='text-area-chat relative px-3 py-2'>
          <div className='attach-wrapper'>
            <AttachedFile data={imageInfo} handleClearFile={handleClearImage} />
          </div>
          <textarea
            ref={textareaRef}
            placeholder={receiver?.subadmin_id && 'Type your message'}
            value={message}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className='messagearea'
            disabled={!receiver?.subadmin_id || imgUploading}
            rows={1}
          />
          {/* imgUploading */}
          <div
            className='send-msg-wrap'
            style={{
              backgroundColor: imgUploading || !isMobile ? 'transparent' : '#BFBFBF',
            }}
          >
            {imgUploading ? (
              <CircularProgress size={16} className=' !text-[12px] chat-send-loader' />
            ) : (
              <East
                className=' !text-[12px] chat-send-button'
                style={{
                  color: receiver?.subadmin_id ? '#BFBFBF' : '#7A7A7A',
                }}
                onClick={sendMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
