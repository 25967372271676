import axios from "axios";
// import { showError } from "../utils/notify";
// console.log(
//   "api url",
//   process.env.REACT_APP_API_URL,
//   process.env.REACT_APP_PUBLIC_URL
// );

const instance = axios.create({
  baseURL: "https://api.theblackjet.biz/api/v2"|| process.env.REACT_APP_API_URL,
  headers: {
    // "Content-Type": "application/json",
  },
});
export const publicApi = axios.create({
  baseURL: "https://api.theblackjet.biz/api/v2"|| process.env.REACT_APP_API_URL,
  headers: {
    // "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("blackjet-website");
    if (token) {
      config.headers.Authorization = `Bearer ${token} `;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // if (response?.data?.statusCode === 401) {
    //   showError("Session Expired");
    //   localStorage.clear();
    //   window.location.href = process.env.REACT_APP_PUBLIC_URL;
    //   console.log(response);
    // }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error)

    return error?.response || {message:"Something went wrong"};
  }
);

export default instance;