import { formattedText } from 'components/Text';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { checkIcon, circleIconDisabled, iconEdit, iconRestore } from './icon';
import { ListWithIcon2 } from 'components/feeback/Menu';
import { isTimeOutdated, truncateText } from 'helpers';
import { socket } from 'utils/socket';
import OnboardingContext, { useGetUserId, useGetUserType } from 'context/OnboardingContext';
import CustomModal from 'components/modal/CustomModal';
import { useMediaQuery } from '@mui/material';
import { IS_CHAT_OPEN } from 'constants/actions';

const UserChat = ({
  chat,
  handleContextMenu,

  editChat,
  selectedChat,
  setUnsendChat,
  handleUpdateMessage,
  setAnchorEl,
}) => {
  //chat onChange value
  const [chatValue, setChatValue] = useState(chat?.message);

  // for when logPress then do not open img
  const signalRef = useRef(null);

  // When edit mode need to increase height of chat box area
  const chatRef = useRef(false);

  // check if chat is in edit mode
  const isEditMode = editChat && selectedChat?._id === chat._id;

  const isTimeOver = isTimeOutdated(chat?.createdAt, 5);
  // console.log(isTimeOver, chat.message, 'isTimeOver___');

  const handleChange = (e) => {
    setChatValue(e.target.value);
  };

  const handleParentClick = (currentTarget, event) => {
    event.stopPropagation();
    // console.log('parent_click', currentTarget);
    if (!isEditMode && isTimeOver) {
      setAnchorEl(currentTarget);
      handleContextMenu(currentTarget, chat);
      signalRef.current = true;
    }
  };

  const handleClickCap = () => {
    signalRef.current = false;
  };

  useEffect(() => {
    if (isEditMode && chatRef?.current) {
      // console.log('ref__qwq', chatRef.current.scrollHeight, chatRef.current.clientHeight);
      chatRef.current.focus();
      chatRef.current.style.height = 'auto'; // Reset height
      chatRef.current.style.height = `${chatRef.current.scrollHeight}px`; // Set height based on content
    }
  }, [chatRef, isEditMode, chatValue]);

  return (
    <>
      <div className='black-jet-user' key={chat._id}>
        <LongPressButton
          role='button'
          onLongPress={handleParentClick}
          onClick={handleClickCap}
          className={`user-bot ${isEditMode && 'edit-user'} `}
          id={chat?._id}
        >
          {!isEditMode && (
            <>
              {chat?.media?.map((data, index) => {
                return <RenderImgAdmin data={data} key={index} signalRef={signalRef} />;
              })}

              <div className='user-text'>{formattedText(chat?.message)}</div>
            </>
          )}
          {isEditMode && (
            <div className='edit-user-container'>
              <div className='edit-user-msg'>
                <textarea
                  ref={chatRef}
                  className='edit-user-msg-input'
                  defaultValue={chat?.message}
                  value={chatValue}
                  rows={1}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {isEditMode && (
            <img
              src={chatValue ? checkIcon : circleIconDisabled}
              alt='check'
              className={'edit-user-icon'}
              style={{
                pointerEvents: chatValue ? 'auto' : 'none',
              }}
              onClick={(e) => handleUpdateMessage(e, chatValue || chat?.message, chat)}
            />
          )}
        </LongPressButton>
      </div>

      {/* <ContextMenu
        x={contextMenu.x}
        y={contextMenu.y}
        visible={contextMenu.visible}
        onClose={() => setContextMenu({ ...contextMenu, visible: false })}
      /> */}
    </>
  );
};

/**
 * ContextMenu component
 *
 * @prop {number} x X position of the ContextMenu
 * @prop {number} y Y position of the ContextMenu
 * @prop {boolean} visible Visibility of the ContextMenu
 * @prop {function} onClose Callback when the ContextMenu is closed
 */

export const ContextMenu = ({ visible, setEditChat, handleUnsendMessage, selectedChat }) => {
  const containerRef = useRef(null);
  const style = {};

  const editObj = {
    icon: iconEdit,
    title: 'Edit',
    onClick: () => setEditChat(true),
  };

  const unsendObj = {
    icon: iconRestore,
    title: 'Unsend',
    onClick: () => handleUnsendMessage(true),
  };

  return (
    <div style={style} className=''>
      <div
        className={`${
          visible ? 'attach-file-container-open' : 'attach-file-container-close'
        } attach-file-container`}
        ref={containerRef}
        style={{ maxHeight: visible ? containerRef?.current?.scrollHeight : 0 }}
      >
        <ul className='attach-file-list'>
          {selectedChat?.message && selectedChat.message && (
            <ListWithIcon2 icon={editObj.icon} title={editObj.title} onClick={editObj.onClick} />
          )}
          <ListWithIcon2
            icon={unsendObj.icon}
            title={unsendObj.title}
            onClick={unsendObj.onClick}
          />
        </ul>
      </div>
    </div>
  );
};

export default UserChat;

export const AdminChat = ({ chat, setChats }) => {
  const [isEndChat, setIsEndChat] = useState(false);
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  const sender_type = useGetUserType();
  const sender = useGetUserId();
  // console.log(chat, 'chat___232');

  const handleChatRefClick = (e) => {
    e.stopPropagation();
    dispatchOnboardingForms({
      type: IS_CHAT_OPEN,
      payload: { open: !onboardingForms.isChatOpen.open, isResize: true },
    });
  };

  const handleDisconnect = (e) => {
    socket.emit('disconnected', { sender, sender_type, messageId: chat?._id });
    handleChatRefClick(e);
    setChats([]);
  };

  const disconnectedInfo = (data) => {
    console.log(data, 'disconnectedInfo___');
    if (data.result) {
      setIsEndChat(true);
    }
  };

  useEffect(() => {
    //     disconnected
    // Request -- sender, sender_type
    if (socket) {
      socket.on('disconnected', disconnectedInfo);
    }
    return () => {
      socket.off('disconnected', disconnectedInfo);
    };
  }, []);

  // console.log('admin_chat', chat);
  if (chat.message_type === 'disconnect') {
    return (
      <div className='black-jet-bot' key={chat._id}>
        <div className='chat-text-disconnect-container'>
          {!chat?.type && (
            <div className='chat-text-disconnect' role='button' onClick={handleDisconnect}>
              End chat session
            </div>
          )}
          {chat?.type && <div className='chat-ended'>You have ended the chat session</div>}
        </div>
      </div>
    );
  }
  return (
    <div className='black-jet-bot' key={chat._id}>
      <img
        alt='television'
        loading='lazy'
        src='/images/img_television.svg'
        className='my-auto aspect-[1.19] w-[19px]'
      />
      <div className='black-jet-bot-text'>
        {/* {chat.message_type === 'image' && <RenderImg img={chat?.image} />} */}
        {/* {chat.message_type === 'video' && <RenderVideo video_url={chat?.image} />} */}
        {chat?.media?.map((data, index) => {
          return <RenderImgAdmin data={data} />;
        })}
        <div className='chat-text'>{formattedText(chat?.message)}</div>
      </div>
    </div>
  );
};

const RenderImgAdmin = ({ data, signalRef }) => {
  const [selectedChat, setSelectedChat] = useState(null);

  const handleSetSelectedChat = (event) => {
    if (signalRef.current) {
      return;
    }
    signalRef.current = false;
    if (data.type === 'file') return;
    setSelectedChat(data);
  };

  return (
    <div role='button' onClick={handleSetSelectedChat}>
      <ImgPreview selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
      <div className='chat-img'>
        {data.type === 'image' && (
          <img src={data.mediaUrl} alt='television' className='rounded-sm h-auto' />
        )}

        {data.type === 'file' && <RenderPdf data={data} />}
        {data.type === 'video' && <RenderVideo data={data} />}
      </div>
    </div>
  );
};

const RenderPdf = ({ data }) => {
  // console.log(data, '____pdf');
  const pdfName = data.mediaUrl.split('/').pop();
  const breakPdfName = pdfName.split('.');
  const fileName = breakPdfName.at(0);
  return (
    <a href={data.mediaUrl} target='_blank' rel='noopener noreferrer'>
      <div className='chat-pdf'>
        {truncateText(fileName, 20)}
        {breakPdfName.at(1)}
      </div>
    </a>
  );
};

const RenderVideo = ({ data }) => {
  // console.log(data, '____video_url');
  return (
    <div>
      <video
        class='rounded-sm h-auto'
        controls
        // controlslist='nofullscreen'
        // playsinline=''
        poster={data.thumbnail}
        role='img'
        // data-inline-media-loop-to='true'
        // data-inline-media-basepath='/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4'
      >
        <source src={data.mediaUrl} type='video/mp4' class='custom-video' />
      </video>
    </div>
  );
};

const ImgPreview = ({ selectedChat, setSelectedChat }) => {
  const isMobile = useMediaQuery('(max-width: 699px)');

  const handleCloseModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSelectedChat(null);
  };
  if (!selectedChat) return null;
  return (
    <div>
      <CustomModal
        openDialog={Boolean(selectedChat)}
        handleCloseDialog={handleCloseModal}
        isTitleRequired={true}
        isActionButtonRequired={true}
        className='dialog-modal-container dialog-modal-container-chat'
        title={''}
        disableClose={false}
        onClick={(e) => e.stopPropagation()}
        maxWidth={`${isMobile ? 'xl' : 'lg'}`}
      >
        <div className='flex items-center justify-center'>
          {selectedChat.type === 'image' && (
            <img src={selectedChat.mediaUrl} alt='television' className='' />
          )}
          {selectedChat.type === 'video' && <RenderVideo data={selectedChat} />}
        </div>
      </CustomModal>
    </div>
  );
};

const LongPressButton = ({ onLongPress, onClick = () => {}, delay = 500, children, ...props }) => {
  const [isPressed, setIsPressed] = useState(false);
  const [pressTimer, setPressTimer] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(
    (evt) => {
      let timer;
      if (isPressed) {
        timer = setTimeout((e) => {
          // console.log('long_press', evt, e);
          onLongPress(currentTarget, event);
          setPressTimer(null);
        }, delay);
        setPressTimer(timer);
      }

      // Cleanup the timer if the component unmounts or the press ends
      return () => {
        clearTimeout(timer);
      };
    },
    [isPressed, onLongPress, delay],
  );

  const handleMouseDown = (e) => {
    e.stopPropagation();
    setIsPressed(true);
    setCurrentTarget(e.currentTarget);
    setEvent(e);
  };

  const handleMouseUp = (e) => {
    e.stopPropagation();
    setEvent(e);
    setIsPressed(false);
    clearTimeout(pressTimer);
    if (pressTimer) {
      onClick(); // Trigger onClick if not long press
    }
  };

  const onMouseLeave = (e) => {
    setEvent(e);
    e.stopPropagation();
    setIsPressed(false);
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={onMouseLeave} // Reset if the user moves the mouse away
      {...props}
    >
      {children}
    </div>
  );
};
